<template>
  <div class="newdetail-style">
    <titles />
    <div class="box1 center-center" v-if="info1.image">
      <video
        v-if="
          info1.animation_url.includes('.mp4') ||
          info1.animation_url.includes('.webm') ||
          info1.animation_url.includes('.ogg')
        "
        :src="info1.animation_url"
        autoplay
        controls
        loop
        class="i1"
      ></video>
      <model-viewer
        v-else-if="
          info1.animation_url.includes('.glb') ||
          info1.animation_url.includes('.gltf')
        "
        :src="info1.animation_url"
        alt="A 3D model of an astronaut"
        shadow-intensity="1"
        camera-controls
        auto-rotate
        ar
        style="width: 375px; height: 375px"
      ></model-viewer>

      <div
        v-else-if="
          info1.animation_url.includes('.mp3') ||
          info1.animation_url.includes('.mpeg') ||
          info1.animation_url.includes('.wav') ||
          info1.animation_url.includes('.ogg')
        "
        :style="{
          backgroundImage: `url(${info1.image})`
        }"
        class="b3"
      >
        <img :src="info1.image" class="i2" style="opacity: 0;" />
        <audio :src="info1.animation_url" autoplay controls loop class="b4"></audio>
      </div>
      <img v-else :src="info1.image" class="i1" />
    </div>
    <div class="box2">
      <div class="row1">{{ info1.title }}#{{ info1.tokenId }}</div>
      <div class="row2 between-center">
        <div class="left start-center" v-if="type != 1">
          <img
            :src="
              info.chain == 0
                ? $concatImg('img81')
                : info.chain == 1
                ? $concatImg('img79')
                : info.chain == 2
                ? $concatImg('img80')
                : info.chain == 3
                ? $concatImg('img82')
                : ''
            "
            class="i1"
          />
          <div class="t1">{{ info.price }} USDT</div>
        </div>
        <div class="right end-center" v-if="type != 1" @click="goLike">
          <img
            v-if="info.is_like == 1"
            src="@/assets/img/img18.png"
            class="i1"
          />
          <img v-else src="@/assets/img/img19.png" class="i1" />
          <div class="t1">{{ info1.heat }}</div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="row1 between-center">
        <div class="left">{{ $t("text.t388") }}</div>
        <div
          class="right end-center"
          @click="
            goAhead(`/bhlist?chain=${info.chain}&contract=${info.contract}`)
          "
        >
          <div class="t1">{{ $t("text.t389") }}</div>
          <van-icon name="arrow" size="20" />
        </div>
      </div>
      <div
        class="row2 between-center"
        @click="goAhead(`/set?id=${info.collection_id}&chainType=${chainType}`)"
      >
        <div class="row2b1">
          <img
            :src="collectionInfo.collectionimage"
            class="i1"
          />
          <img v-if="collectionInfo.identification == 1" src="@/assets/img/img85.png" class="left-imgs-pos" />
          <img v-else-if="collectionInfo.identification == 2" src="@/assets/img/img86.png" class="left-imgs-pos" />
        </div>
        <div class="row2t1 van-ellipsis">
          {{ collectionInfo.collectionname }}
        </div>
        <van-icon name="arrow" size="16" color="#909090" class="row2icon" />
      </div>
      <div
        class="row3 start-center"
        v-if="info.classificationInfo && info.classificationInfo.length"
      >
        <div
          class="item"
          v-for="item in info.classificationInfo"
          :key="item.id"
        >
          # {{ language == 1 ? item.title : item.etitle }}
        </div>
      </div>
      <div class="row4 end-center">
        <!-- <img :src="info.chain==0?$concatImg('img81'):info.chain==1?$concatImg('img79'):info.chain==2?$concatImg('img80'):info.chain==3?$concatImg('img82'):''" class="i1" /> -->
        <img
          :src="
            chain_list.find((ele) => ele.chain_type == info.chain)?.chain_logo
          "
          class="i1"
        />
        <div class="t1">
          {{ chain_list.find((ele) => ele.chain_type == info.chain)?.name }}
        </div>
      </div>
    </div>
    <div class="box4" v-if="info.casting_type == 1">
      <div class="item">
        <div class="title">{{ $t("text.t390") }}</div>
        <div class="b4box">
          {{ casting.introduce }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ $t("text.t391") }}</div>
        <div class="b4box">
          {{ casting.content }}
        </div>
      </div>
      <div class="item">
        <div class="title">{{ $t("text.t392") }}</div>
        <div class="b4box">
          {{ casting.about }}
        </div>
      </div>
    </div>
    <div
      class="box5 between-center"
      @click="goAhead(`/jslist?chain=${info.chain}&contract=${info.contract}`)"
    >
      <div class="left">{{ $t("text.t393") }}</div>
      <van-icon name="arrow" size="16" />
    </div>
    <div class="box6 between-start" id="tomodel">
      <div class="left start-center">
        <div
          class="item mr30"
          :class="active == 1 ? 'ac' : 'no'"
          @click="changeActive(1)"
        >
          {{ $t("text.t394") }}
        </div>
        <div
          class="item"
          :class="active == 2 ? 'ac' : 'no'"
          @click="changeActive(2)"
        >
          {{ $t("text.t395") }}
        </div>
      </div>
      <div class="right end-center">
        <div class="t1">{{ $t("text.t396") }}</div>
        <img src="@/assets/img/img81.png" class="i1" />
        <!-- <div class="t2">{{info.casting_type == 1?'True Shop':'场外'}}</div> -->
        <div class="t2">True Shop</div>
      </div>
    </div>
    <div class="box7" v-show="active == 1">
      <div class="row1 botline">
        <div class="title">{{ $t("text.t397") }}</div>
        <div class="list">
          <div class="item between-center">
            <div>{{ $t("text.t104") }}</div>
            <div class="end-center">
              <div>
                {{ info.contract?.slice(0, 6) }}...{{
                  info.contract?.slice(info.contract.length - 5)
                }}
              </div>
              <img
                src="@/assets/img/img75.png"
                class="i1"
                @click="copy(info.contract)"
              />
            </div>
          </div>
          <div class="item between-center">
            <div>Token ID</div>
            <div class="end-center">
              <div>{{ info.tokenid }}</div>
              <!-- <img
                src="@/assets/img/img75.png"
                class="i1"
                @click="copy(info.tokenid)"
              /> -->
            </div>
          </div>
          <div class="item between-center">
            <div>{{ $t("text.t106") }}</div>
            <div class="end-center">
              {{
                info.type == 0 ? "ERC-721" : info.type == 1 ? "ERC-1155" : ""
              }}
            </div>
          </div>
          <div class="item between-center">
            <div>{{$t('text.t127')}}</div>
            <div class="end-center">
              {{ info.amount }}
            </div>
          </div>
          <div class="item between-center">
            <div>{{ $t("text.t398") }}</div>
            <div class="end-center">
              {{ chain_list.find((ele) => ele.chain_type == info.chain)?.name }}
            </div>
          </div>
          <div class="item between-center">
            <div>{{ $t("text.t294") }}</div>
            <div class="end-center">
              <div>
                {{ info.address?.slice(0, 6) }}...{{
                  info.address?.slice(info.address.length - 5)
                }}
              </div>
              <img
                src="@/assets/img/img75.png"
                class="i1"
                @click="copy(info.address)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row1">
        <div class="title">{{ $t("text.t399") }}</div>
        <div class="list1 between-center">
          <div
            class="item1"
            v-for="(item, index) in info1.attributes
              ? info1.attributes
              : []"
            :key="index"
          >
            <div class="t1">{{ item.trait_type }}</div>
            <div class="t2">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box8" v-show="active == 2">
      <div class="list">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('text.t39')"
          :offset="10"
          @load="getlist"
        >
          <div class="item" v-for="item in list" :key="item.id">
            <div class="row1 between-center">
              <img :src="info1.image" class="i1" />
              <div class="b1">
                <div class="t1">{{ $t("text.t400") }}</div>
                <div class="t2">{{ $parseTime(item.createtime) }}</div>
              </div>
              <div class="b2 end-center">
                <div class="left">
                  <div class="t1">{{ item.total }} USDT</div>
                  <div class="t2">{{ $t("text.t401") }}</div>
                </div>
                <van-icon
                  v-if="item.is_open"
                  name="arrow-up"
                  size="12"
                  color="#909090"
                  @click="changeOpen(item)"
                />
                <van-icon
                  v-else
                  name="arrow-down"
                  size="12"
                  color="#909090"
                  @click="changeOpen(item)"
                />
              </div>
            </div>
            <div class="row2 around-center" v-show="item.is_open">
              <div class="row2item center-center">
                <div class="r2t1">{{ $t("text.t402") }}</div>
                <div class="r2t2">
                  {{ item.seller?.slice(0, 6) }}...{{
                    item.seller?.slice(item.seller.length - 5)
                  }}
                </div>
                <img
                  src="@/assets/img/img75.png"
                  class="r2i1"
                  @click="copy(item.seller)"
                />
              </div>
              <div class="row2item center-center">
                <div class="r2t1">{{ $t("text.t403") }}</div>
                <div class="r2t2">
                  {{ item.address?.slice(0, 6) }}...{{
                    item.address?.slice(item.address.length - 5)
                  }}
                </div>
                <img
                  src="@/assets/img/img75.png"
                  class="r2i1"
                  @click="copy(item.address)"
                />
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <div class="buybox center-center" v-loading="isloading">
      <div
        class="btns center-center"
        v-if="type == 1 && info.user_id == uid"
        @click="
          goAhead(
            `/upinfo?nft_id=${info.id}&chain=${info.chain}`
          )
        "
      >
        {{ $t("text.t36") }}
      </div>
      <div class="btns center-center" v-else-if="type == 2" @click="downNow">
        {{ $t("text.t100") }}
      </div>
      <div class="btns center-center" v-else-if="type == 3" @click="buyNow">
        {{ $t("text.t404") }}
      </div>
    </div>
  </div>
</template>

<script>
import "@google/model-viewer";
import titles from "@/components/titles/index.vue";
import { mapState } from "vuex";
import BigNumber from "bignumber.js";
export default {
  components: {
    titles,
  },
  data() {
    return {
      active: 1,
      id: "",
      isup: "",
      info: {},
      info1: {},
      casting: {},
      collectionInfo: {},
      orderno: "",
      pshow: false,
      stepNum: 1,
      isloading: false,
      tmsg: "",
      type: "", // 1未上架 2已上架 3购买
      istruenft: 2, //1显示 2隐藏
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      chainType: "",
      uid: ""
    };
  },
  computed: {
    ...mapState(["device", "language", "chainId", "config", "chain_list"]),
  },
  created() {
    this.uid = this.$local.get("shop:uid");
    console.log(this.uid);
    this.id = this.$route.query.id;
    this.orderno = this.$route.query.orderno;
    this.chainType = this.$route.query.chainType;
    this.type = this.$route.query.isup ? 1 : "";
    if (this.type == 1) {
      this.getdetail();
    } else {
      this.getNewdetail();
    }
  },
  methods: {
    changeOpen(item) {
      for (let i = 0; i < this.list.length; i++) {
        if (item.id == this.list[i].id) {
          this.list[i].is_open = !this.list[i].is_open;
        }
      }
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        tokenid: this.info.tokenid,
        contract: this.info.contract,
        chainType: this.info.chain,
        language: this.language,
      };
      this.$http.get("/core/history", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            data[i].is_open = false;
          }
        }
        this.list = [...this.list, ...data];
      });
    },
    changeActive(e) {
      this.$nextTick(() => {
        let targetbox = document.getElementById('tomodel');
        let target = targetbox.offsetTop;
        document.documentElement.scrollTop = target;
        this.active = e;
      })
    },
    gofans() {
      this.$http
        .post("/order/fans", {
          orderno: this.info.orderno,
          language: this.language,
        })
        .then((res) => {
          this.$toast(res.msg);
          this.info.is_fans = this.info.is_fans == 0 ? 1 : 0;
        });
    },
    goLike() {
      this.$http
        .post("/collection/likeTokenid", {
          orderno: this.info.orderno,
          chainType: this.chainType,
          language: this.language,
        })
        .then((res) => {
          this.$toast(res.msg);
          this.info.is_like == 2 ? this.info1.heat++ : this.info1.heat--;
          this.info.is_like = this.info.is_like == 2 ? 1 : 2;
        });
    },
    async buyNow() {
      const chainNow = this.chain_list.find(
        (ele) => ele.chain_id == this.chainId
      ).chain_type;
      console.log(chainNow);
      if (chainNow != this.info.chain) {
        this.$toast(this.$t("text.t405"));
        return;
      }
      if (this.info.number < 1) return;
      try {
        this.isloading = true;
        await this.$http.post("/order/checkPrice");
        const res = await this.$http.post("/order/buyOrder", {
          orderno: this.orderno,
          language: this.language,
          number: this.stepNum,
        });
        const { seller, address, contract, tokenid, number, price, orderno,lid } =
          res.data;
        const result = await this.checkAllowance(price);
        console.log(result);
        this.buyNft(
          seller,
          address,
          contract,
          tokenid,
          Number(number),
          price,
          orderno,
          lid
        );
      } catch (error) {
        console.log(error);
        this.isloading = false;
      }
    },
    async downNow() {
      const chainNow = this.chain_list.find(
        (ele) => ele.chain_id == this.chainId
      ).chain_type;
      console.log(chainNow);
      if (chainNow != this.info.chain) {
        this.$toast(this.$t("text.t527"));
        return;
      }
      if (this.info.number < 1) return;
      try {
        this.isloading = true;
        const res = await this.$http.post("/order/soldOrder", {
          orderno: this.orderno,
          language: this.language,
          number: this.stepNum,
        });
        const { address, contract, tokenid, number, price, orderno } = res.data;
        // const result = await this.checkAllowance(price);
        // console.log(result);
        this.downNft(
          address,
          contract,
          tokenid,
          Number(number),
          orderno
        );

        // const rest = await this.sendHash(hash, orderno);
        // await this.$http.post("/order/pendingOrder");
        // this.$toast(rest.msg);
        // this.isloading = false;
        // this.pshow = false;
        // // this.getNewdetail()
        // setTimeout(() => {
        //   this.$bus.$emit("reload_newest");
        //   this.$router.go(-1);
        // }, 1000);
      } catch (error) {
        console.log(error);
        this.isloading = false;
      }
    },
    async checkAllowance(pay) {
      const user_address = this.$local.get("shop:account");
      const web3 = new this.Web3(ethereum);
      let f_max_num = new BigNumber(2).pow(256).minus(1); //最大值
      const max_num = f_max_num.toString(16);
      console.log(max_num);
      const contract_from =
        this.chainId == "0x2d20"
          ? this.config.omp_usdt_contract
          : this.chainId == "0x38"
          ? this.config.bsc_usdt_contract
          : this.chainId == "0x1"
          ? this.config.eth_usdt
          : this.chainId == "0x89"
          ? this.config.pol_usdt
          : ""; // 被授权的合约地址
      const abi_from =
        this.chainId == "0x2d20"
          ? JSON.parse(this.config.omp_usdt_abi)
          : JSON.parse(this.config.bsc_usdt_abi);
      const contract_to =
        this.chainId == "0x2d20"
          ? this.config.omp_market_contract
          : this.chainId == "0x38"
          ? this.config.bsc_market_contract
          : this.chainId == "0x1"
          ? this.config.eth_market
          : this.chainId == "0x89"
          ? this.config.pol_market
          : ""; // 授权的合约地址

      const contract_proto = new web3.eth.Contract(abi_from, contract_from, {
        from: user_address,
      });
      const isAllowance = await contract_proto.methods
        .allowance(user_address, contract_to)
        .call();
      console.log(isAllowance);
      console.log(new BigNumber(isAllowance).gte(web3.utils.toWei(pay,this.chainId == "0x89" || this.chainId == "0x1"?'mwei':'ether')));
      if (new BigNumber(isAllowance).gte(web3.utils.toWei(pay,this.chainId == "0x89" || this.chainId == "0x1"?'mwei':'ether'))) {
        return true;
      } else {
        const result1 = await contract_proto.methods
          .approve(contract_to, "0x" + max_num)
          .send();
        console.log(result1);
        return true;
      }
    },
    buyNft(seller, address, contract, tokenid, number, price, orderno,lid) {
      const web3 = new this.Web3(ethereum);
      const contract_to =
        this.chainId == "0x2d20"
          ? this.config.omp_market_contract
          : this.chainId == "0x38"
          ? this.config.bsc_market_contract
          : this.chainId == "0x1"
          ? this.config.eth_market
          : this.chainId == "0x89"
          ? this.config.pol_market
          : "";
      const abi_to =
        this.chainId == "0x2d20"
          ? JSON.parse(this.config.omp_market_abi)
          : JSON.parse(this.config.bsc_market_abi);
      const nft_contract = new web3.eth.Contract(abi_to, contract_to, {
        from: address,
      });
      nft_contract.methods
        .buyTokenUsingUSDT(
          seller,
          contract,
          tokenid,
          number,
          web3.utils.toWei(price,this.chainId == "0x89" || this.chainId == "0x1"?'mwei':'ether')
        )
        .send({ from: address })
        .on("transactionHash", async (hash) => {
          const rest = await this.sendHash(hash, orderno);
          this.tmsg = "";
          this.tmsg = rest.msg;
        })
        .on("receipt", (receipt) => {
          this.$http.post("/order/pendingOrderBuy", { lid }).then(() => {
            this.$toast(this.tmsg);
            this.isloading = false;
            this.pshow = false;
            this.$http.post("/order/checkPrice");
            setTimeout(() => {
              this.$bus.$emit("reload_newest");
              this.$router.go(-1);
            }, 1000);
          });
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.isloading = false;
        });
    },
    downNft(address, contract, tokenid, number, orderno) {
      const web3 = new this.Web3(ethereum);
      const contract_to =
        this.chainId == "0x2d20"
          ? this.config.omp_market_contract
          : this.chainId == "0x38"
          ? this.config.bsc_market_contract
          : this.chainId == "0x1"
          ? this.config.eth_market
          : this.chainId == "0x89"
          ? this.config.pol_market
          : "";
      const abi_to =
        this.chainId == "0x2d20"
          ? JSON.parse(this.config.omp_market_abi)
          : JSON.parse(this.config.bsc_market_abi);
      const nft_contract = new web3.eth.Contract(abi_to, contract_to, {
        from: address,
      });
      nft_contract.methods
        .cancelAskOrder(contract, tokenid, number)
        .send()
        .on("transactionHash", async (hash) => {
          const rest = await this.sendHash(hash,orderno)
          this.tmsg = "";
          this.tmsg = rest.msg;
        })
        .on("receipt", async (receipt) => {
          console.log(receipt);
          await this.$http.post('/order/pendingOrder')
          this.isloading = false;
          this.$toast(this.tmsg);
          this.pshow = false;
          setTimeout(() => {
            this.$bus.$emit("reload_newest");
            this.$router.go(-1);
          }, 1000);
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.isloading = false;
        })
    },
    async sendHash(hash, orderno) {
      const arg = {
        hash,
        orderno,
        language: this.language,
      };
      const rest = await this.$http.post("/order/addHash", arg);
      return rest;
    },
    makePshow() {
      this.pshow = true;
    },
    getNewdetail() {
      this.$http
        .get("/order/marketDetail", {
          orderno: this.orderno,
          chainType: this.chainType,
          language: this.language,
        })
        .then((res) => {
          const userId = this.$local.get("shop:uid");
          this.type = res.data.user_id == userId ? 2 : 3;
          console.log(this.type);
          this.info = res.data;
          this.info1 = res.data.info;
          this.casting = res.data.casting;
          this.collectionInfo = res.data.collectionInfo;
        });
    },
    getdetail() {
      this.$http
        .get("/collections/collectionDetail", {
          nft_id: this.id,
          chainType: this.chainType,
          language: this.language,
        })
        .then((res) => {
          this.info = res.data;
          this.info1 = res.data.info;
          this.casting = res.data.casting;
          this.collectionInfo = res.data.collectionInfo;
        });
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   const whitelist = [
  //     "Bhlist"
  //   ];
  //   console.log(whitelist.includes(to.name));
  //   if (whitelist.includes(to.name)) {
  //     from.meta.keepAlive = true;
  //   } else {
  //     from.meta.keepAlive = false;
  //   }
  //   next();
  // },
};
</script>

<style lang="less">
.newdetail-style {
  padding: 0 0 90px 0;
  position: relative;
  .box1 {
    padding: 15px 0;
    .i1 {
      width: 70%;
      height: auto;
    }
    .i2 {
      width: 100%;
      height: auto;
    }
    .b3{
      width: 100%;
      background-position: center center;
      background-size: 100% 100%;
      position: relative;
      .i2 {
        width: 100%;
        height: auto;
      }
    }
    .b4{
      opacity: 0.7;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .box2 {
    padding: 0 28px 20px;
    border-bottom: 1px solid #e1e1e1;
    .row1 {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 10px;
    }
    .row2 {
      .left {
        .i1 {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
        .t1 {
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
      }
      .right {
        .i1 {
          width: 18px;
          height: 14px;
          margin-right: 10px;
        }
        .t1 {
          font-size: 14px;
          font-weight: bold;
          color: #323232;
        }
      }
    }
  }
  .box3 {
    padding: 13px 28px 20px;
    border-bottom: 1px solid #e1e1e1;
    .row1 {
      font-weight: bold;
      color: #323232;
      margin-bottom: 15px;
      .left {
        font-size: 15px;
      }
      .right {
        font-size: 12px;
        margin-right: 10px;
      }
    }
    .row2 {
      background: #f8f8f8;
      border-radius: 5px;
      padding: 6px 14px;
      margin-bottom: 15px;
      .row2b1 {
        width: 28px;
        height: 28px;
        margin-right: 10px;
        position: relative;
        .i1 {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .left-imgs-pos{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          right: -2px;
          bottom: -2px;
        }
      }
      .row2t1 {
        flex: 1;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
      .row2icon {
        margin-left: 10px;
      }
    }
    .row3 {
      flex-wrap: wrap;
      margin-bottom: 10px;
      .item {
        background: #f8f8f8;
        padding: 7px 11px;
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        margin-right: 15px;
        margin-bottom: 6px;
        border-radius: 50px;
      }
    }
    .row4 {
      .i1 {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
      .t1 {
        font-size: 12px;
        font-weight: 500;
        color: #323232;
      }
    }
  }
  .box4 {
    padding: 20px 28px 26px;
    border-bottom: 1px solid #e1e1e1;
    .item {
      margin-bottom: 20px;
      .title {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 10px;
      }
      .b4box {
        background: #f8f8f8;
        border-radius: 10px;
        padding: 18px 15px 23px;
        font-size: 12px;
        font-weight: bold;
        color: #323232;
      }
    }
    .item:last-of-type {
      margin-bottom: 0;
    }
  }
  .box5 {
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 30px;
    .left {
      font-size: 15px;
      font-weight: bold;
      color: #323232;
    }
  }
  .box6 {
    padding: 15px 21px 0 28px;
    border-bottom: 1px solid #e1e1e1;
    .left {
      .item {
        font-size: 12px;
        font-weight: bold;
        padding-bottom: 15px;
      }
      .ac {
        color: #323232;
        border-bottom: 1px solid #323232;
      }
      .no {
        color: #909090;
      }
      .mr30 {
        margin-right: 30px;
      }
    }
    .right {
      .t1 {
        font-size: 12px;
        font-weight: 500;
        color: #909090;
      }
      .i1 {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin: 0 5px;
      }
      .t2 {
        font-size: 12px;
        font-weight: 500;
        color: #323232;
      }
    }
  }
  .box7 {
    .row1 {
      padding: 15px 25px 0;
      .title {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 15px;
      }
      .list {
        .item {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
          margin-bottom: 15px;
          .i1 {
            width: 12px;
            height: 12px;
            margin-left: 3px;
          }
        }
      }
      .list1 {
        flex-wrap: wrap;
        .item1 {
          width: 151px;
          border: 1px solid #e1e1e1;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 10px;
          margin-bottom: 20px;
          .t1 {
            font-size: 12px;
            font-weight: 500;
            color: #909090;
          }
          .t2 {
            font-size: 15px;
            font-weight: bold;
            color: #323232;
            padding: 4px 0;
          }
        }
      }
    }
    .botline {
      border-bottom: 1px solid #e1e1e1;
    }
  }
  .box8 {
    .list {
      .item {
        padding: 15px 0;
        border-bottom: 1px solid #e1e1e1;
        .row1 {
          padding: 0 20px;
          .i1 {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-right: 8px;
          }
          .b1 {
            flex: 1;
            .t1 {
              font-size: 12px;
              font-weight: bold;
              color: #323232;
              margin-bottom: 10px;
            }
            .t2 {
              font-size: 12px;
              font-weight: 500;
              color: #b2b2b2;
            }
          }
          .b2 {
            .left {
              text-align: right;
              margin-right: 10px;
              .t1 {
                font-size: 12px;
                font-weight: bold;
                color: #323232;
                margin-bottom: 10px;
              }
              .t2 {
                font-size: 12px;
                font-weight: 500;
                color: #b2b2b2;
              }
            }
          }
        }
        .row2 {
          padding-top: 25px;
          .row2item {
            .r2t1 {
              font-size: 12px;
              font-weight: 500;
              color: #909090;
            }
            .r2t2 {
              font-size: 12px;
              font-weight: 500;
              color: #323232;
              margin: 0 5px;
            }
            .r2i1 {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
  .buybox {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 90px;
    border-top: 1px solid #e1e1e1;
    background-color: #fff;
    z-index: 99;
    .btns {
      width: 328px;
      height: 50px;
      background: #323232;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>